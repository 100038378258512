.awards {
  display: flex;
  flex-direction: column;
}

.awards__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  justify-content: center;
  gap: 20px;
  list-style-type: none;
}

.item {
  position: relative;
  width: 100px;
  height: 100px;
  /* overflow: hidden; */
  /* border-radius: 50%; */
}

.item__description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: transform .5s, opacity .2s, visibility .2s;
  display: flex;
  align-items: center;
  border-radius: 50%;
}

.item__text {
  text-align: center;
  width: 100%;
  font-size: 10px;
  line-height: 1.2;
  font-weight: 400;
  font-style: normal;
  margin: 10px;
}

.item__pic {
  height: 100px;
  object-fit: contain;
  width: 100%;
}

.item:hover .item__description {
  visibility: visible;
  opacity: 1;
  cursor: default;
  transform: scale(120%);
}

.awards__title {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  font-style: normal;
  margin: 0 auto 25px;
  padding-top: 100px;
}

@media (max-width: 1070px) {
  .awards__title {
    padding-top: 75px;
  }
}

@media (max-width: 720px) {
  .awards__title {
    padding-top: 50px;
  }
}