.newsform {
  display: flex;
  flex-direction: column;
  padding: 100px;
  background-color: #f2eac7;
}

@media (max-width: 1000px) {
  .newsform {
    padding: 75px;
  }
}

@media (max-width: 600px) {
  .newsform {
    padding: 50px;
  }
}

.newsform__title {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 25px;
}

@media (max-width: 600px) {
  .newsform__title {
    font-size: 18px;
  }
}

.newsform__conteiner {
  display: flex;
  justify-content: space-between;
}

.newsform__form {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
}

@media (max-width: 800px) {
  .newsform__form {
    flex-direction: column;
  }
}

.newsform__form-conteiner {
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  border: 1.5px solid rgba(59, 58, 45, 0.2);
  margin-right: 20px;
  /* background-color: #fff; */
  background-color: rgba(255, 255, 255, 0.6);

}

@media (max-width: 800px) {
  .newsform__form-conteiner {
    margin: 0;
    margin-bottom: 20px;
  }
}

.newsform__form-item {
  border: none;
  width: 100%;
  background-color: #fff;
  font-family: inherit;
  font-size: 16px;
  line-height: 36px;
  font-weight: 400;
  font-style: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 6px;
  box-shadow: none;
  transition: box-shadow 0.3s ease-out;
}

.newsform__form-item:focus {
  box-shadow: 0px 0px 10px #cbc6a8a3;
  outline: 1.5px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 1000px) {
  .newsform__form-item {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media (max-width: 600px) {
  .newsform__form-item {
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.newsform__form-button {
  background: none;
  border: none;
  outline: none;
  height: 36px;
  border-radius: 6px;
  background-color: rgb(224, 79, 79);
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  line-height: 36px;
  font-weight: 500;
  font-style: normal;
  padding: 0 20px;
}

.newsform__form-button:enabled {
  transition: background-color 0.3s ease-in-out;
}

.newsform__form-button:enabled:hover {
  background-color: rgb(224, 110, 100);
}

.newsform__form-button_disabled {
  /* transition: opacity 0.3s ease-in-out;
  opacity: 0.1; */
  transition: background-color 0.3s ease-in-out;
  background-color: rgba(224, 79, 79, .1);
  cursor: default;
}

.newsform__form-item::-webkit-input-placeholder {
  color: #a0a0a0;
}

.newsform__error {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  font-style: normal;
  color: #e04f4f;
  /* text-align: center; */
  padding: 10px 20px;
  width: 100%;
  margin: auto;
}

@media (max-width: 1000px) {
  .newsform__error {
    padding: 10px 18px;
  }
}

@media (max-width: 600px) {
  .newsform__error {
    font-size: 12px;
    padding: 10px 16px;
  }
}