.searchform {
  padding: 25px 25px 50px;
}

@media (max-width: 1000px) {
  .searchform {
    padding: 25px 25px 50px;
  }
}

@media (max-width: 600px) {
  .searchform {
    padding: 25px 25px 50px;
  }
}

.searchform__form {
  display: flex;
  align-items: center;
}

@media (max-width: 1000px) {
  .searchform__form {
    flex-direction: column;
  }
}

.searchform__form-conteiner {
  position: relative;
  display: flex;
  padding: 0;
  align-items: center;
  width: 100%;
  border-radius: 50px;
  border: 1.5px solid rgba(59, 58, 45, 0.2);
  background-color: #fff;
}

.searchform__form-item {
  border: none;
  width: 100%;
  background-color: transparent;
  font-family: inherit;
  font-size: 16px;
  line-height: 48px;
  font-weight: 400;
  font-style: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 22px;
  padding-right: 7px;
  border-radius: 50px;
  box-shadow: none;
  transition: box-shadow 0.3s ease-out;
}

.searchform__form-item:focus {
  box-shadow: 0px 0px 10px #cbc6a8a3;
  outline: 1.5px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 1000px) {
  .searchform__form-item {
    padding-left: 18px;
  }
}

@media (max-width: 600px) {
  .searchform__form-item {
    font-size: 14px;
    padding-left: 16px;
  }
}

.searchform__form-button {
  background: none;
  border: none;
  outline: none;
  background-image: url(../../images/find-item.svg);
  background-repeat: no-repeat;
  background-position: center;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  /* background-color: #3B3A2D; */
  background-color: rgb(224, 79, 79);
  cursor: pointer;
  position: absolute;
  right: 7px;
  top: 7px;
}

.searchform__form-button:enabled {
  transition: background-color 0.3s ease-in-out;
}

.searchform__form-button:enabled:hover {
  background-color: rgb(224, 110, 100);
}

.searchform__form-button_disabled {
  /* transition: opacity 0.3s ease-in-out;
  opacity: 0.1; */
  transition: background-color 0.3s ease-in-out;
  background-color: rgba(224, 79, 79, .1);
  cursor: default;
}

.searchform__form-item::-webkit-input-placeholder {
  color: #a0a0a0;
}
