.logo-partner {
  height: 64px;
}

.logo-partner__image {
  height: 100%;
}

@media (max-width: 1000px) {
  .logo-partner {
    height: 50px;
  }
}

@media (max-width: 600px) {
  .logo-partner {
    height: 40px;
  }
}
