.about-project {
  display: flex;
  flex-direction: column;
  padding: 100px;
  min-height: 300px;
}

@media (max-width: 1000px) {
  .about-project {
    padding: 75px;
  }
}

@media (max-width: 600px) {
  .about-project {
    padding: 50px;
  }
}

.about-project__title {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 25px;
}

@media (max-width: 600px) {
  .about-project__title {
    font-size: 18px;
  }
}

.about-project__conteiner {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 900px) {
  .about-project__conteiner {
    flex-direction: column;
  }
}

.about-project__column {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  font-style: normal;
  width: 45%;
}

@media (max-width: 900px) {
  .about-project__column {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .about-project__column {
    font-size: 14px;
  }
}

.about-project__paragraph{
  margin-bottom: 10px;
}