.promo {
  width: 100%;
}

.promo__conteiner {
  display: flex;
  min-height: calc(100vh - 114px);
  background-image: url("../../../images/background_1.JPG");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  box-shadow:  inset 0px 5px 5px rgba(0, 0, 0, .2);
}

@media (max-width: 1000px) {
    .promo__conteiner {
      min-height: calc(100vh - 90px);
    }
  }
  
  @media (max-width: 600px) {
    .promo__conteiner {
      min-height: calc(100vh - 60px);
    }
  }

.promo__title {
  font-size: 50px;
  line-height: 1.2;
  font-weight: 500;
  font-style: normal;
  max-width: 500px;
  margin: auto 0;
  padding-left: 60px;
  color: #fff;
  text-shadow: rgb(40, 40, 40) 1px 1px 10px;;
}

@media (max-width: 1000px) {
    .promo__title {
      font-size: 40px;
    }
  }
  
  @media (max-width: 600px) {
    .promo__title {
      font-size: 32px;
      text-align: center;
      margin: auto;
      padding: 10px;
      max-width: 400px;
    }
  }
