.partners {
  display: flex;
  flex-direction: column;
  padding: 100px;
}

@media (max-width: 1000px) {
  .partners {
    padding: 75px;
  }
}

@media (max-width: 600px) {
  .partners {
    padding: 50px;
  }
}

.partners__title {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 25px;
}

@media (max-width: 600px) {
  .partners__title {
    font-size: 18px;
  }
}

.partners__list {
  display: flex;
  justify-content: center;
  gap: 40px;
  list-style-type: none;
}

@media (max-width: 600px) {
  .partners__list {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}
