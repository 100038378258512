.footer {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 25px 50px;
  background-color: rgb(50, 50, 50);
  color: rgb(150, 150, 150);
}

@media (max-width: 1000px) {
  .footer {
    padding: 20px 25px;
  }
}

@media (max-width: 600px) {
  .footer {
    padding: 10px 10px;
  }
}

.footer__conteiner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 700px) {
  .footer__conteiner {
    flex-direction: column-reverse;
    margin-top: 30px;
  }
}

.footer__text {
  min-height: 30px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  font-style: normal;
}

.footer__copyright {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  font-style: normal;
}

.footer__link {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 2;
  font-weight: 400;
  font-style: normal;
  color: rgb(150, 150, 150);
  text-decoration: none;
}


@media (max-width: 700px) {
  .footer__copyright {
    margin: 30px 0;
  }
}

.footer__icon {
  height: 22px;
  margin-right: 10px;
  opacity: .3;
}

@media (max-width: 700px) {
  .footer__icon {
    height: 20px;
    margin-right: 8px;
  }
}
