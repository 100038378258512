.root {
  font-family: "Inter", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  background-color: #fff;
  color: #000;
  /* min-width: 320px; */
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.root__navigation {
  display: none;
  padding: 8px;
  /* background-color: rgba(0, 0, 0, .2); */
}

@media (max-width: 500px) {
  .root__navigation {
    display: block;
  }
}