.popup {
  font-family: "Inter", Arial, sans-serif;
  position: fixed;
  z-index: 100;
  color: #000;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity .5s;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
  cursor: pointer;
}

.popup__conteiner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 260px;
  cursor: default;
  background-color: #fff;
  margin: 30px 30px auto auto;
  padding: 38px;
  border-radius: 8px;
  overflow: hidden;
  transform: translateY(-400px);
  transition: transform 2s;
}

.popup__conteiner_opened {
  transform: translateY(0px);
}

.popup__close-button {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  cursor: pointer;
  background: none;
  outline: none;
  background-color: #fff;
}

.popup__close-button:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 2px;
  top: 0px;
  left: 8px;
  background-color: #000;
  transform: rotate(45deg);
}

.popup__close-button:after {
  position: absolute;
  content: "";
  height: 18px;
  width: 2px;
  top: 0px;
  left: 8px;
  background-color: #000;
  transform: rotate(-45deg);
}

.popup__text {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  font-style: normal;
}
