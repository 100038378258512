.about {
  padding-top: 25px;
}

.about__title {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 900;
  font-style: normal;
  padding-bottom: 15px;
}

.about__text {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  font-style: normal;
}

@media (max-width: 600px) {
  .about__text {
    font-size: 14px;
  }
}

.about__text_paragraph {
  margin-top: 15px;
}