@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Inter-Regular.woff2") format("woff2"),
       url("./Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("./Inter-Black.woff2") format("woff2"),
       url("./Inter-Black.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("./Inter-Medium.woff2") format("woff2"),
       url("./Inter-Medium.woff") format("woff");
}