.icon {
  height: 31px;
  margin-right: 12px;
}

.icon__image {
  /* height: 31px; */
  height: 100%;
}

@media (max-width: 1000px) {
  .icon {
    height: 25px;
    margin-right: 10px;
  }
}

@media (max-width: 700px) {
  .icon {
    height: 20px;
    margin-right: 8px;
  }
}

.icon__hover {
  transition: opacity 0.3s ease-in-out;
  opacity: 0.8;
}

.icon__hover:hover {
  opacity: 1;
}
