.element {
  width: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fdfdfd;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

.element:hover {
  transform: scale(103%);
  cursor: pointer;
}

@media (max-width: 1070px) {
  .element {
    width: 180px;
  }
}

@media (max-width: 720px) {
  .element {
    width: 160px;
  }
}

.element__pic {
  height: 300px;
  object-fit: cover;
  object-position: top;
  width: 100%;
}

@media (max-width: 1070px) {
  .element__pic {
    height: 270px;
  }
}

@media (max-width: 720px) {
  .element__pic {
    height: 250px;
  }
}

.element__title {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 400;
  font-style: normal;
  padding: 5px;
  margin: auto;
  text-align: center;
}

.element__link {
  display: flex;
}
