.project {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  min-height: 600px;
  width: 100%;
  background-image: linear-gradient(
    180deg,
    #fff 10%,
    #e8e0bc 55%,
    #fff 95%
  );
  z-index: 0;
}

.project__image_num_1 {
  position: absolute;
  top: 50px;
  right: -50px;
  height: 500px;
  width: 400px;
  z-index: -1;
  background-image: url("../../../images/pic-1.svg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .8;
}

.project__image_num_2 {
  position: absolute;
  top: 600px;
  left: -230px;
  height: 750px;
  width: 750px;
  z-index: -1;
  background-image: url("../../../images/pic-2.svg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .8;
}

.project__image_num_3 {
  position: absolute;
  top: 300px;
  left: 250px;
  height: 40px;
  width: 40px;
  z-index: -1;
  background-image: url("../../../images/pic-3.svg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .8;
}

.project__image_num_4 {
  position: absolute;
  top: 350px;
  left: 150px;
  height: 90px;
  width: 90px;
  z-index: -1;
  background-image: url("../../../images/pic-4.svg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .8;
}

.project__image_num_5 {
  position: absolute;
  top: 310px;
  right: 330px;
  height: 100px;
  width: 100px;
  z-index: -1;
  background-image: url("../../../images/pic-4.svg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .8;
}

.project__image_num_6 {
  position: absolute;
  top: 1400px;
  right: -10px;
  height: 700px;
  width: 600px;
  z-index: -1;
  background-image: url("../../../images/pic-6.svg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .8;
}

.project__image_num_7 {
  position: absolute;
  top: 2000px;
  left: 600px;
  height: 50px;
  width: 50px;
  z-index: -1;
  background-image: url("../../../images/pic-3.svg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .8;
}

.project__image_num_8 {
  position: absolute;
  top: 2000px;
  right: 530px;
  height: 110px;
  width: 110px;
  z-index: -1;
  background-image: url("../../../images/pic-4.svg");
  background-repeat: no-repeat;
  background-size: cover;
  /* opacity: .8; */
}