.discription {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(180deg, #fff 50%, #e8e0bc 100%);
  z-index: 0;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 100px;
  box-shadow:  inset 0px 5px 5px rgba(0, 0, 0, .2);
}

@media (max-width: 1000px) {
  .discription {
    padding: 75px;
  }
}

@media (max-width: 600px) {
  .discription {
    padding: 50px;
  }
}

.discription__button {
  background: none;
  border: none;
  outline: none;
  height: 36px;
  width: 100px;
  border-radius: 6px;
  background-color: rgb(224, 79, 79);
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  line-height: 36px;
  font-weight: 500;
  font-style: normal;
  margin: 100px auto 0;
  text-decoration: none;
  text-align: center;
}

@media (max-width: 1000px) {
  .discription__button {
    margin: 75px auto 0;
  }
}

@media (max-width: 600px) {
  .discription__button {
    margin: 50px auto 0;
  }
}

.discription__title {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  font-style: normal;
}

.discription__image_num_1 {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 600px;
  width: 800px;
  z-index: -1;
  background-image: url("../../images/pic-7.svg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.8;
}

.discription__image_num_2 {
    position: absolute;
    top: 750px;
    right: 0px;
    height: 500px;
    width: 450px;
    z-index: -1;
    background-image: url("../../images/pic-8.svg");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .3;
  }
  
  .discription__image_num_3 {
    position: absolute;
    top: 550px;
    left: 450px;
    height: 90px;
    width: 90px;
    z-index: -1;
    background-image: url("../../images/pic-4.svg");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .8;
  }
