.elements {
    padding: 0 75px 25px;
  }
  
  @media (max-width: 1000px) {
    .elements {
      padding: 0 50px 25px;
    }
  }
  
  @media (max-width: 630px) {
    .elements {
      padding: 0 25px 25px;
    }
  }

  .elements__title {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    font-style: normal;
    margin: 0 25px;
  }
  
  .elements__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
    justify-content: center;
    gap: 40px 32.5px;
    list-style-type: none;
  }
  
  @media (max-width: 1070px) {
    .elements__list {
      grid-template-columns: repeat(auto-fit, 180px);
      gap: 40px 26px;
    }
  }
  
  @media (max-width: 720px) {
    .elements__list {
      grid-template-columns: repeat(auto-fit, 160px);
      gap: 40px 16px;
    }
  }
  
  .elements__message {
    text-align: center;
    margin-bottom: 70px;
  }
  
  @media (max-width: 600px) {
    .elements__message {
      margin-bottom: 40px;
    }
  }