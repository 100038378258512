.header {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  background-color: #f2eac7;
  padding: 25px 50px;
}

@media (max-width: 1000px) {
  .header {
    padding: 20px 25px;
  }
}

@media (max-width: 600px) {
  .header {
    padding: 10px 10px;
  }
}

.header__section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header__conteiner {
  display: flex;
  margin-left: auto;
}

@media (max-width: 500px) {
  .header__conteiner {
    display: none;
  }
}

.header__burger-button {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0px;
  top: 0px;
  border: none;
  cursor: pointer;
  background: none;
  outline: none;
  background-color: #3b3a2d;
}

.header__burger-button::before {
  position: absolute;
  content: "";
  height: 5px;
  width: 22px;
  top: 4px;
  left: 0px;
  background-color: #f2eac7;
}

.header__burger-button::after {
  position: absolute;
  content: "";
  height: 5px;
  width: 22px;
  top: 13px;
  left: 0px;
  background-color: #f2eac7;
}

.header__close-button {
  width: 22px;
  height: 22px;
  position: absolute;
  right: 0px;
  top: 0px;
  border: none;
  cursor: pointer;
  background: none;
  outline: none;
}

.header__close-button::before {
  position: absolute;
  content: "";
  height: 23px;
  width: 3px;
  top: -1px;
  left: 10px;
  background-color: #3b3a2d;
  transform: rotate(45deg);
}

.header__close-button::after {
  position: absolute;
  content: "";
  height: 23px;
  width: 3px;
  top: -1px;
  left: 10px;
  background-color: #3b3a2d;
  transform: rotate(-45deg);
}

.header__navigation {
  position: relative;
  width: 22px;
  height: 22px;
  margin-left: auto;
  display: none;
}

@media (max-width: 500px) {
  .header__navigation {
    display: block;
  }
}
