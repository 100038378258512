.video {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.video__conteiner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 25px;
  margin-bottom: 75px;
}

@media (max-width: 1000px) {
  .video__conteiner {
    margin-bottom: 50px;
  }
}

@media (max-width: 600px) {
  .video__conteiner {
    margin-bottom: 25px;
  }
}

.video__iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0 auto;
}
