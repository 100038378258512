.navigation {
  display: flex;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.navigation__border {
  width: 12px;
  height: 31px;
  border-left: 1px solid #3b3a2d;
  opacity: 0.8;
}

@media (max-width: 1000px) {
  .navigation__border {
    width: 10px;
    height: 25px;
  }
}

@media (max-width: 600px) {
  .navigation__border {
    width: 8px;
    height: 20px;
  }
}

.navigation__button {
  height: 31px;
  cursor: pointer;
  color: #3b3a2d;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  line-height: 31px;
  font-weight: 500;
  font-style: normal;
}

@media (max-width: 1000px) {
  .navigation__button {
    line-height: 25px;
    height: 25px;
  }
}

@media (max-width: 600px) {
  .navigation__button {
    line-height: 20px;
    height: 20px;
    font-size: 14px;
  }
}
